// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Roboto", sans-serif;
}

#container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  box-sizing: border-box;
}

.model-viewer-style {
  border: 1.5px solid darkgrey;
  border-radius: 0.45rem;
  min-height: 380px;
  flex: 1;
}

#model-viewer-planta {
  flex: 1;
}

.empreendimento-title {
  letter-spacing: 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,qCAAA;EACA,iCAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,4BAAA;EACA,sBAAA;EACA,iBAAA;EACA,OAAA;AACJ;;AAEA;EACI,OAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":["html, body{\n    overflow-x: hidden;\n    margin: 0;\n    padding: 0;\n    font-family: 'Bebas Neue', sans-serif;\n    font-family: 'Roboto', sans-serif;\n}\n\n#container{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    max-width: 1000px;\n    max-height: 1000px;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    padding: 1rem;\n    box-sizing: border-box;\n}\n\n.model-viewer-style{\n    border: 1.5px solid darkgrey;\n    border-radius: 0.45rem;\n    min-height: 380px;\n    flex: 1;\n}\n\n#model-viewer-planta{\n    flex: 1;\n}\n\n.empreendimento-title{\n    letter-spacing: 0.1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
