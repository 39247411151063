import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";

import '../styles/main.scss';

import logo_img from '../assets/imgs/logo.png'

const img_logo = document.getElementById('logo');
img_logo.src = logo_img;

//gcloud app deploy --project quavo-ar
